import React from 'react';
import * as ReactRedux from 'react-redux';
import { Alert, Button, Card, Group, List, Text, ThemeIcon } from '@mantine/core';
import { CircleCheck, Pencil, PhotoUp } from 'tabler-icons-react';
import NextImage from 'next/image';
import * as Types from '../declarations';
import * as Actions from '../redux/actions';
import * as Selectors from '../redux/selectors';
import { getDefaultImage } from 'lib/helper';
interface MyComponentProps {
  variant: 'button' | 'modal' | 'banner' | 'alert';
  version: Types.VERSION;
  closeModal?: () => void;
}
const AccountNudge: React.FC<MyComponentProps> = ({
  variant,
  version,
  closeModal
}: MyComponentProps) => {
  const dispatch = ReactRedux.useDispatch();
  const openSignUp = () => {
    closeModal?.();
    dispatch(Actions.openSignUp());
  };
  const authedUser = ReactRedux.useSelector(Selectors.authedUser);
  const isAuthedUser = !!authedUser;
  const title = `Join a community of 
	${version === Types.VERSION.PEAKS ? 'mountaineers' : 'trekkers'}`;
  return <div className="account-nudge" data-sentry-component="AccountNudge" data-sentry-source-file="AccountNudge.tsx">
			{variant === 'button' && <div>
					<Button
      // variant="white"
      // color="blue"
      // fullWidth
      // mt="md"
      // radius="md"
      size="lg" onClick={openSignUp}>
						Join the community
						{/* {title} */}
					</Button>
				</div>}
			{variant === 'modal' && <div>
					<Card shadow="sm" padding="lg" radius="md" withBorder>
						<Card.Section>
							<NextImage src={getDefaultImage(version)} height={200} width={404}
          // height={160}
          alt={`Guided ${version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'}`} />
						</Card.Section>

						<Group position="apart" mt="md" mb="xs">
							<Text weight={500}>{title}</Text>
							{/* <Badge color="pink" variant="light">
        On Sale
        </Badge> */}
						</Group>

						<Text size="sm" color="dimmed">
							<p>Join a community so that you can:</p>
							<List spacing="xs" size="sm" center icon={<ThemeIcon color="teal" size={24} radius="xl">
										<CircleCheck size="1rem" />
									</ThemeIcon>}>
								<List.Item>
									Learn about new{' '}
									{version === Types.VERSION.PEAKS ? 'expeditions and mountaineering experiences' : 'treks'}
								</List.Item>

								<List.Item>Read articles as published</List.Item>

								<List.Item icon={<ThemeIcon color="teal" size={24} radius="xl">
											<PhotoUp size="1rem" />
										</ThemeIcon>}>
									Contribute pictures of routes
								</List.Item>
								<List.Item icon={<ThemeIcon color="teal" size={24} radius="xl">
											<Pencil size="1rem" />
										</ThemeIcon>}>
									Edit/correct/add information for routes
								</List.Item>
								{/* // todo: ask/answer questions
         // todo: get notified about new routes 
         // todo: receive discounted trips */}
							</List>
						</Text>

						<Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={openSignUp}>
							Join
						</Button>
					</Card>
				</div>}
			{variant === 'banner' && <div>Banner content</div>}
			{variant === 'alert' && !isAuthedUser && <Alert title={title} mb="xs">
					<Group noWrap align="center">
						<div style={{
          flexGrow: 1,
          marginRight: 10
        }}>
							{/* Text takes up the remaining space */}
							Connect with guides, receive news on new expeditions/routes,
							upload pictures, share/edit routes.
						</div>
						<Button
        // variant="light"
        color="blue" radius="md" onClick={openSignUp} style={{
          whiteSpace: 'nowrap'
        }} // Ensures the button text does not wrap
        >
							Join
						</Button>
					</Group>
				</Alert>}
		</div>;
};
export default AccountNudge;